@import "@scss/styleguide";

@mixin futuraExperimentationLayout {
    @include futuraRegular;
    font-size: 11px;
}

.experimentationsSection{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
}

.titleContainer {
    display: flex;
    width: 700px;
    justify-content: space-between;
    padding: 0 20px;
    font-size: 28px;
    font-family: $futura;
    @media (max-width: 768px) {
        width: 96%;
        font-size: 14px;
        padding: 0;
      }
}

.heroHeaderTextMedium {
    font-weight: $medium;
}

.heroHeaderTextSpacing {
    font-weight: $light;
    letter-spacing: 6px;
}

.natureContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    margin-bottom: 30px;
    @media (max-width: 768px) {
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 160px;
      }
}

.natureContainer img{
    width: 305px;
    height: 548px;
    @media (max-width: 768px) {
        width: 50%;
        height: auto;
      }
}

.natureText {
    position: absolute;
    top: 50%;
    left: 88%;
    width: 40%;
    text-align: left;
    transform: translateY(-50%);
    line-height: 1.3;
    @include futuraExperimentationLayout;
    @media (max-width: 768px) {
        top: 125%;
        left: 50%; 
        transform: translate(-50%, -50%); 
        width: 80%; 
        display: flex;
        flex-direction: column;
        text-align: center;
        font-weight: $medium;  
    }
}

.atmosphereContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    margin-bottom: 30px;
    width: 100%; 
}

.atmosphereContainer img {
    object-fit: cover;
    width: 100%; 
    height: 484px;
}

.atmosphereText{
    position: absolute;
    top: 50%;
    width: 40%;
    text-align: left;
    transform: translateY(-50%);
    line-height: 1.3;
    @include futuraExperimentationLayout;
    @media (max-width: 768px) {
        width: 90%;
    }

}

.atmosphereText p{
    text-align: center;
    color: white
}

.materialContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    @media (max-width: 768px) {
        justify-content: space-around;
        position: relative;
        margin-top: 100px;
      }
}

.materialLeftImg{
    object-fit: contain;
    width: 525px;
    height: 350px;
    padding-right: 10px;
    @media (max-width: 768px) {
        width: 40%;
        height: auto;
      }
}

.materialRightImg{
    object-fit: contain;
    width: 412px;
    height: 528px;
    @media (max-width: 768px) {
        width: 40%;
        height: auto;
      }
}

.materialText {
    position: absolute;
    left: 20px;
    width: 20%;
    text-align: left;
    line-height: 1.3;
    @include futuraExperimentationLayout;
    @media (max-width: 768px) {
        width: 90%;
        top: -120px;
        text-align: center;
        left: auto;
        font-weight: $medium;
      }
}

.formContainer{
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    gap: 10px;
    align-items: flex-end;
    margin-bottom: 100px;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
      }
    
}

.formContainer img{
    object-fit: cover;
    width: 76%;
    height: 484px;
    @media (max-width: 768px) {
        width: 80%;
        height: auto;
    }
}

.formText{
    width: 20%;
    line-height: 1.3;
    @include futuraExperimentationLayout;
    @media (max-width: 768px) {
        width: 70%;
        text-align: center;
        font-weight: $medium;
      }
}

.timeSection{
    width: 100%;
    margin-bottom: 50px;
    @media (max-width: 768px) {
        margin-bottom: 0;
    }
}

.timeFirstContainer {
    display: flex;
    align-items: center;
    margin-bottom: 100px;
    @media (max-width: 768px) {
        flex-direction: column-reverse;
        position: relative;
        margin: 0;
    }
}
.timeFirstContainerImgContainer{
    @media (max-width: 768px) {
        display: flex;
        justify-content: center;
        margin: 0;
    }
}

.timeFirstContainer img {
    margin-left: 150px;
    object-fit: cover;
    width: 366px;
    height: 531px;
    @media (max-width: 768px) {
        width: 55%;
        height: 360px;
        margin: 0px;
        margin-right: 0 !important;
    }
}

.timeFirstContainerText {
    margin-top: -20px;
    width: 20%;
    margin-left: 20px;
    @include futuraExperimentationLayout;
    @media (max-width: 768px) {
        width: 90%;
        font-weight: $medium;
        text-align: center;
        margin: 0;
    }
}

.timeFirstContainerTitle {
    margin-top: 50px;
    margin-left: 20px; 
    @include futuraExperimentationLayout;
    @media (max-width: 768px) {
        position: absolute;
        top: 90px;
        left: 0;
        font-weight: $medium;
    }
}

.timeSecondContainer{
    width: 100%;
    margin-bottom: 100px;
    @media (max-width: 768px) {
        display: none;
    }
}

.timeSecondContainer img{
    object-fit: cover;
    width: 100%;
    height: 484px;
}

.timeThirdContainer {
    display: flex;
    justify-content: center; 
    margin-left: 90px;
    gap: 20px;
    margin-bottom: 100px;
    @media (max-width: 768px) {
        display: none;
    }
}

.timeThirdContainerText{
    @include futuraExperimentationLayout;
    width: 30%;
    margin-right: 20px;
}

.timeThirdContainer img{
    object-fit: cover;
    width: 359px;
    height: 526px;
}

.timeFourthContainer{
    margin-bottom: 50px;
    @media (max-width: 768px) {
        display: flex;
        justify-content: center;
        margin-bottom: 0;
    }
}

.timeFourthContainerText{
    @include futuraExperimentationLayout;
    width: 50%;
    margin-left: 10px;
    @media (max-width: 768px) {
        width: 90%;
        text-align: center;
        font-weight: $medium;
        margin: 0;
    }
}

.timeFifthContainer img{
    object-fit: cover;
    width: 100%;
    height: 484px;
    @media (max-width: 768px) {
        display: none;
    }
}
