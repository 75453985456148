@import "@scss/styleguide";

.footerSticky {
  font-family: $montserrat;
  font-weight: $medium;
  position: sticky;
  font-size: 11px;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: $white;
  @media (max-width: 768px) {
    font-size: 9px;
    padding: 0 10px;
  }
}

.footerRightSide {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footerImage {
  width: 48px;
  height: 48px;
  margin-right: 50px;

  @media (max-width: 768px) {
    width: 36px;
    height: 36px;
    margin-right: -5px;
  }
}

.scrollTop {
  cursor: pointer;
  color: $black;
}
