@import '@scss/styleguide';

.projectsSection {
  text-align: center;
  padding: 150px 0 80px 0;
  height: 75vh;
  @media (max-width: 768px) {
    text-align: left;
    padding: 80px 0 80px 0;
  }
}

.description {
  @include futuraLayout;
  text-align: center;
  margin-top: 20px;
  line-height: 1px;
}

.projectsSectionTitle{
  margin-bottom: 0;
  font-weight: $regular;
  @media (max-width: 768px) {
    font-size: 16px;
    margin-left: 6px;
    margin-bottom: 5px;
  }
}

.projectsImagesContainer {
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.imageContainer {
  position: relative;
  width: 50%;
  height: 331px;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    margin-top: -5px;
  }
}

.projectImage {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}

.imageTitle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: transparent;
  font-family: 'Libre Franklin', sans-serif;
  font-weight: $light;
  pointer-events: none;
  letter-spacing: 4px;
}

.imageContainer:hover .imageTitle {
  transition: 0.4s;
  color: $white;
}

@media (max-width: 768px) {
  .imageContainer .imageTitle {
    color: $white; 
  }
}
