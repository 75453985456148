@import "@scss/styleguide";

.commercialContainer{
    padding: 60px 0;
}

.commercialContainer img{
    cursor: pointer;
}

.commercialTitleContainer{
    display: flex;
    padding: 0 20px 70px;
}

.commercialFirstTitle{
    @include projectLandingHeader;
    font-weight: $medium;
}

.commercialSecondTitle{
    @include projectLandingHeader;
    font-weight: $light;
    margin-left: 10px;
}

.commercialProjectsFirstRow{
    display: flex;
    justify-content: space-between;
}

.projectCard{
    position: relative;
}

.projectCard img {
    max-width: 100%;
}

.projectCardMarginTop{
    margin-top: 90px;
}

.commercialProjectsThirdRow{
    display: flex;
    justify-content: space-between;
    margin-top: 130px;
}

.commercialProjectsThirdRowRightColumn{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.comercialContainerMobile{
    margin-bottom: 100px;
}