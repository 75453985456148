@import "@scss/styleguide";

@mixin rightColumnContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

@mixin projectsRow{
    display: flex;
    justify-content: space-between;
}

.residentialContainer{
    padding-top: 60px;
}

.residentialContainer img{
    cursor: pointer;
}

.residentialTitleContainer{
    display: flex;
    padding: 0 20px 70px;
}

.residentialFirstTitle{
    @include projectLandingHeader;
    font-weight: $medium;
}

.residentialSecondTitle{
    @include projectLandingHeader;
    font-weight: $light;
    margin-left: 10px;
}

.residentialProjectsFirstRow{
    @include projectsRow;
}



.projectCard{
    position: relative;
}

.projectCardMarginTop{
    margin-top: 90px;
}

.projectCardMarginLeft{
    margin-left: 430px;
}

.residentialProjectsSecondRow{
    @include projectsRow;
    margin-top: 90px;
}

.residentialProjectsThirdRow{
    @include projectsRow;
    margin-top: 100px;
}

.residentialProjectsThirdRowRightColumn{
    @include rightColumnContainer;
    margin-top: 10px;
}

.projectCardExtraMarginTop{
    margin-top: 130px;
}

.residentialProjectsSecondRowRightColumn{
    @include rightColumnContainer;
}

.casaBonsi{
    margin-top: 320px;
}

.casaAltillo{
    margin-top: 160px;
}

.residentialContainerMobile{
    margin-bottom: 100px;
}
