@import "@scss/styleguide";

.carouselContainer {
  max-height: 500px;
  overflow: hidden;
  object-fit:cover;
}

.carouselImageContainer {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-bottom: 20px;
}

.carouselImage {
  max-width: 1080px;
  width: 100%;
  height: auto;
  cursor: pointer;
}

.openGalleryText {
  width: 150px;
  padding: 10px;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 5px;
  text-align: center; 
  font-family: 'Libre Franklin', sans-serif;
  font-weight: $light;
  letter-spacing: 1px;
  color: $black;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.openGalleryText:hover {
  background-color: #F0F0F0;
}

.galleryButton {
  display: flex;
  text-align: center;
  justify-content: center;
  font-family: 'Libre Franklin', sans-serif;
  font-weight: $light;
  margin-top: 50px; 
  color: $black;
  cursor: pointer;
  transition: none;
}