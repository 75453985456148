// [------------------------------ FONTS ------------------------------]

$futura: 'Jost', sans-serif;
$roboto: 'Roboto', sans-serif;
$montserrat: 'Montserrat', sans-serif;

// [--------------------------- FONT WEIGHT ---------------------------]

$light: 300;
$regular: 400;
$medium: 500;

// [----------------------------- MIXINS ------------------------------]

@mixin futuraLight {
  font-family: $futura;
  font-weight: $light;
}

@mixin futuraRegular {
  font-family: $futura;
  font-weight: $regular;
}

@mixin futuraMedium {
  font-family: $futura;
  font-weight: $medium;
}

@mixin futuraLayout {
  font-family: $futura;
  font-size: 11px;
}

// [--------------------- GLOBAL TYPOGRAPHY CLASSES -------------------]

@mixin projectLandingHeader{
  font-family: $futura;
  font-size: 20px;
}

// [--------------------- GLOBAL TYPOGRAPHY CLASSES -------------------]

h1 {
  font-family: $montserrat
}

a{
  text-decoration: none;
}
