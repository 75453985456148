@import '@scss/styleguide';

.teamSectionContainer{
  padding-top: 60px;
}

.teamSectionTitle {
  @include futuraRegular;
  font-size: 27px; 
  text-align: center;
}

.grizzoHistory {
  display: flex;
  align-items: center;
  margin: 0 50px 200px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 20px 100px;
  }
}

.grizzoHistoryText {
  @include futuraRegular;
  font-size: 11px; 
  margin-right: 20px;

  @media (max-width: 768px) {
    @include futuraMedium;
    text-align: center; 
    font-size: 14px;
    font-kerning: normal;
    margin-right: 0px;
  }
}

.grizzoHistory img {
  object-fit: cover;
  width: 346px;
  height: 484px;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    margin-top: 20px;
  }
}

.teamCardFirstRow{
  display: flex;
  justify-content: center; 
  gap: 200px; 
  @media (max-width: 768px) {
    flex-direction: column; 
    align-items: center; 
    gap: 20px; 
    margin: 0 20px 50px;
  }
}

.teamCardSecondRow{
  display: flex;
  justify-content: center; 
  gap: 200px; 
  @media (max-width: 768px) {
    flex-direction: column; 
    align-items: center; 
    gap: 20px; 
    margin: 0 20px 50px;
  }
}

.teamCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 256px;
  height: 384px;

  @media (max-width: 768px) {
    align-items: flex-start; 
    text-align: left;
    width: 100%; 
    height: auto; 
  }
}

.teamCard img {
  width: 100%;
  height: auto;
}

.teamCard p {
  @include futuraRegular;
  font-size: 11px; 
  margin-top: 10px;

  @media (max-width: 768px) {
    @include futuraMedium;
    font-size: 13px; 
    margin-top: 10px; 
  }
}

.teamCardMarginBottom{
  margin-bottom: 100px;
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
}
