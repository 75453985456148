// [------------------------------ Z-INDEX LEVELS ------------------------------]

$z-index-base: 1;
$z-index-header: 10;
$z-index-gallery: 20;

// [------------------------------ GLOBAL CLASSES ------------------------------]

.image-gallery-image{
  max-width: calc(100vw - 160px);
}

.hide-scroll {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none;
}

.hide-scroll::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
