@import "@scss/styleguide";

@mixin nav{
  position: absolute;
  color: $white;
  background-color: transparent;
  cursor: pointer;
  border: 0;
  margin: 0 10px;
}

.galleryOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $z-index-gallery;
  background-color: $blackOverlay;
}

.closeIconContainer {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: $z-index-gallery;
  cursor: pointer;
}

.closeIconContainer img{
  width: 20px;
  height: 20px;
}

.imageGalleryContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.leftNav{
  @include nav;
  left: 0;
}

.leftNav img{
  width: 25px;
  height: 25px;
}

.rightNav{
  @include nav;
  right: 0;
}

.rightNav img{
  width: 25px;
  height: 25px;
}