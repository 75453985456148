@import "@scss/styleguide";

@mixin projectsRow{
    display: flex;
    justify-content: space-between;
}

.publicationContainer{
    padding-top: 60px;
}

.publicationContainer img{
    cursor: pointer;
}

.publicationTitleContainer{
    display: flex;
    padding: 0 20px 40px;
}

.publicationFirstTitle{
    @include projectLandingHeader;
    font-weight: $medium;
}

.publicationProjectsFirstRow{
    @include projectsRow;
}

.publicationProjectsSecondRow{
    @include projectsRow;
}

.projectCard{
    position: relative;
}

.projectCardExtraMarginTop{
    margin-top: 180px;
}

.publicationsContainerMobile{
    margin-bottom: 100px;
}
