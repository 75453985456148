@import "@scss/styleguide";

@mixin robotoMedium {
  font-family: $roboto;
  font-weight: $medium;
}

.heroSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding-bottom: 50px;
}

.heroHeader {
  display: flex;
  width: 700px;
  justify-content: space-between;
  padding: 0 20px;
  margin-top: -12px;
  font-size: 28px;
  font-family: $futura;
  @media (max-width: 768px) {
    margin-top: 2px;
    padding: 0;
    width: 96%;
    font-size: 14px;
  }
}

.heroHeaderTextMedium {
  font-weight: $medium;
}

.heroHeaderTextSpacing {
  font-weight: $light;
  letter-spacing: 6px;
}

.heroFuturaTextContainer {
  @include futuraLayout;
  font-weight: $medium;
  width: 100%;
  margin-top: -37px;
  background-color: $white;
}

.heroFuturaTextContainerSecond {
  margin-top: 0;
}

.heroFuturaTextFirstRow {
  display: flex;
  justify-content: space-between;
  padding-right: 30px;
}

.heroFuturaTextSecondRow {
  display: flex;
  justify-content: space-between;
  margin-top: -24px;
}

.heroFuturaTextThirdRow {
  display: flex;
  justify-content: center;
  margin-top: -26px;
}


.backgroundHero{
  position: relative;
}


.backgroundHero img {
  width: 100%;
}

.backgroundHero {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 10px;

  .overlay {
    cursor: pointer;
    width: 50%;
    height: 50%;
    position: absolute;
  }
}