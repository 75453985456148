@import "@scss/styleguide";

@mixin projectImageTitleAndNumber{
    font-family: $futura;
    font-size: 15px;
    font-weight: $medium;
    letter-spacing: 2px;
}

.imageTitleAndDescriptionContainer{
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 360px;
    margin-left: 30px;
    @media (max-width: 768px) {
        margin-top: 20px;
        margin-left: 10px;
        max-width: 100%;
        
    }
}

.imageTitleContainer{
    display: flex;
}

.imageTitleFirst{
    font-family: $futura;
    font-weight: $light;
    font-size: 15px;
    padding-right: 5px;
}

.imageTitleSecond{
    @include projectImageTitleAndNumber
}

.imageDescription{
    font-family: $futura;
    font-size: 11px;
}

.imageNumber{
    font-family: $futura;
    font-size: 15px;
}
