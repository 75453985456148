@import "@scss/styleguide";

.aboutSection {
  margin-bottom: 20px;
  @media (max-width: 768px) {
    margin-bottom: 5px;
  }
}

.aboutSectionTitle {
  text-align: center;
  @media (max-width: 768px) {
    text-align: left;
    margin-left: 6px;
  }
}

.grizzoText {
  font-weight: $medium;
  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.aboutText {
  font-weight: $light;
  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.aboutSectionImageContainer {
  position: relative;
  margin-top: -33px;
  @media (max-width: 768px) {
    margin-top: -30px;
  }
}

.buttonPublications {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 768px) {
    top: 15%;
    left: 29%;
  }
}

.buttonTeam {
  position: absolute;
  top: 50%;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 40px;
  @media (max-width: 768px) {
    top: 85%;
    right: 22%;
  }
}

.button {
  font-family: 'Libre Franklin', sans-serif;
  font-weight: $light;
  cursor: pointer;
  color: transparent;
  margin: 10px 0; /* Añade un espacio entre los botones */
  letter-spacing: 4px;
}

/* Alinea "Nosotros" a la derecha */
.button:last-child {
  margin-left: auto;
}

.aboutSectionImage {
  object-fit: cover;
  width: 100%;
  height: 432px;
  padding-top: 10px;
  background-color: $white;
  @media (max-width: 768px) {
    height: 80vh;
  }
}

.aboutSectionImageContainer:hover .button {
  transition: 0.4s;
  color: white;
}

@media (max-width: 768px) {
  .aboutSectionImageContainer .button {
    color: $white; 
  }
}