// [------------------------------ STATIC COLORS ------------------------------]

$white: rgb(255, 255, 255);
$white2: rgb(247, 247, 247);
$whiteOpacity: rgba(255, 255, 255, 0.8);
$black: rgb(0, 0, 0);
$transparentWhite: rgba(255, 255, 255, 0); 
$danger: rgb(255, 0, 0);
$blackOverlay: rgba(23, 23, 23, 0.8);
$gray1: rgb(40, 44, 52);
$lightBlue: rgb(97, 218, 251);

// [------------------------------ ALPHA COLORS ------------------------------]
@function black($decimalOpacity: 1) {
  @return rgba(0, 0, 0, $decimalOpacity);
}
