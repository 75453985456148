@import "@scss/styleguide";

@mixin projectImageTitleAndNumber{
    font-family: $futura;
    font-size: 15px;
    font-weight: $medium;
}

.detailProjectContainer{
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.titleWrapper{
  @media (min-width: 1080px) {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;
  margin: 40px 60px;
  @media (min-width: 1080px) {
    max-width: 1080px;
    width: 100%;
    margin: 40px 0;
  } 
}

.projectTitleAndNumber{
  font-family: $futura;
  font-size: 15px;
  font-weight: $medium;
}

.projectTitleFirst{
    font-family: $futura;
    font-size: 15px;
}

.projectTitleSecond{
    @include projectImageTitleAndNumber
}

.projectNumber{
    font-family: $futura;
    font-size: 15px;
}

.carouselContainer{
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 50px;
  }
}

.galleryButton{
  cursor: 'pointer'
}