@import "@scss/styleguide";
$backgroundHeaderImage: url("../../assets/images/backgrounds/background_header2.jpg");

.headerContainer {
  position: relative;
}

.headerSticky {
  position: fixed;
  width: 100%;
  height: 51px;
  top: 0;
  z-index: $z-index-header;
  align-items: center;
  transition: background-color 0.3s, color 0.3s;
  color: $white;
}

.headerStickySection{
  color: $black;
}

.headerStickyScrolling {
  background-color: $white;
  color: $black ;
}

.headerFirstRow{
  display: flex;
  justify-content: space-between;
}

.languageSwitch {
  @include futuraLayout;
  margin: 20px 0 0 20px;
  cursor: pointer;
}

.hamburgerMenu {
  margin: 20px 20px 0 0;
  cursor: pointer;
  display: block;
}

.hamburgerMenuContainer{
  width: 100%;
  margin-top: 5px;
  padding-bottom: 10px;
  text-align: end;
  display:none;
}

.hamburgerMenuContainerScrolling{
  background-color: $white;
}

.menuLink{
  font-size: 11px;
  margin-top: 3px;
  padding-right: 20px;
  cursor: pointer;
}

.menuLink{
  font-family: $futura;
  color: $white
}

.menuLink a{
  cursor: pointer;
  color: $white;
}

.menuLinkScrolling a{
  color: $black;
}

.menuLinkScrolling{
  color: $black;
}

.menuLinkSection div{
  color: $black;
}

.menuVisible{
  display: block;
}

.headerBackground {
  position: relative;
  height: 90vh;
  margin-top: -40px;
  overflow: hidden;
  @media (max-width: 768px) {
    height: 95vh;
  }
}

.headerBackground::before {
  content: "";
  background-image: $backgroundHeaderImage;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  filter: contrast(70%);
  scale: 1.5;
}

.headerBackgroundLogo {
  position: absolute;
  bottom: 35px;
  left: 0;
  width: 100%;
  height: auto;
  @media (max-width: 768px) {
    bottom: 0;
  }
}

.headerBackgroundLogo img {
  width: 100%;
  height: auto;
}

.headerBackgroundTitle span {
  font-weight: $light;
}

.headerDescription {
  @include futuraLayout;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  color: $white;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.headerDescriptionLeft,
.headerDescriptionRight {
  flex: 1;
}
.headerDescriptionLeft {
  margin-left: 3%;
}

.headerDescriptionRight {
  text-align: right;
  margin-right: 5%;
}

.headerDescription{
  @media (max-width: 768px) {
    display: none;
  }
}
